import NavBar from "./NavBar";
import MainContent from "./MainContent";
import Foot from './Footer'

export default function App() {
  return (
    <>
      <NavBar/>
      <MainContent/>
      <Foot/>

    </>

  );
}
