import {Row,Card,Image} from 'react-bootstrap'

function Splash(){
  return(

    <Row id='home' className='justify-content-center'>
      <p className='text-center mt-3 mb-3 h4 '>
        Welcome to AWG Pressure Washing
      </p>
      
      <Card className="col-4 border-0 bg-white">
        <Image className="mt-2" src="./AWG_Logo.jpg"></Image>
        <p className='mt-3 mb-3 text-center h6'>Serving Snohomish, King, and Pierce Counties</p>
      
      </Card>
      <Card className='col-9 border-0 bg-white'>
      <p className='text-center mt-3 mb-3 h4'>
        My name is Michael Treat and I am the owner operater of AWG Pressure Washing. 
      </p>
      </Card>


    </Row>
  )
}

export default Splash;