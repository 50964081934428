import Container from 'react-bootstrap/Container';

// Content
import Splash from './Splash';
import Intro from './Intro'

//Pages
import Services from './Services';
import GalleryCarousel from './Gallery';
import Payments from './Payments';



function MainContent(){

  return (
    <Container fluid className='mt-3 bg-white'>
      <Splash/>
      <Intro/>
      <Services/>
      <GalleryCarousel/>
      <Payments/>
    </Container>
  )
}

export default MainContent;