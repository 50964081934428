import {Row, Card, Image} from 'react-bootstrap'

function Intro(){
  return(
    <Row className='justify-content-center bg-white'> 
        <Card className="border-0 justify-content-end bg-white col-4">
          <Image src='./WorkFace.jpg'></Image>
        </Card>
    
        <Card className='col-4 border-0 bg-white'>
        <Image src='./PinkFlowers.jpeg'></Image>
        </Card>
      </Row>
  )
}

export default Intro;