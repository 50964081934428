import {Row,Image,Accordion,Card} from 'react-bootstrap';

function Services() {
  return (
    <Row id='services' className='justify-content-center'>
      
      <Card className='col-8 text-center bg-white border-0'>
      <p className='mt-4 mb-4 h4'>
        I specialize in: 
      </p>

      <Accordion defaultActiveKey='0'>
        <Accordion.Item eventKey="0">
          <Accordion.Header >Pressure Washing</Accordion.Header>
          <Accordion.Body >
            <p className='mb-3'>
              I provide pressure washing services for all exterior surfaces. I primarily work on ground-level, but I also work on siding up to 2 stories high.
            </p>
            <p>Services start at $250</p>
            <Image className='w-50' src='../img/2024-04-PW/Driveway_Before.jpg'></Image>
            <Image className='w-50' src='../img/2024-04-PW/Driveway_After.jpg'></Image>  
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header >Moss Removal</Accordion.Header>
          <Accordion.Body>
            <p>
              Moss that becomes overgrown can become slippery and begin to damage your structure. I use a pressure washer to remove moss from ground level structures. For Roof Moss Removal please see Roof Cleaning below.
            </p>
            <p>Services start at $250</p>
            <Image className='w-50' src='../img/2024-04-PW/MossRemoval_Before.jpg'></Image>
            <Image className='w-50' src='../img/2024-04-PW/MossRemoval_After.jpg'></Image> 
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Roof Cleaning</Accordion.Header>
          <Accordion.Body>
            <p>
              I remove moss and debris from the roof using a bristle brush and hard point as this approach is best for the overall roof health. I always include a free Gutter Cleaning in our Roof Cleaning services!
            </p>
            <p>Services start at $350</p>
            <Image className='w-50' src='../img/2024-04-PW/RoofMoss_Before.jpg'></Image>
            <Image className='w-50' src='../img/2024-04-PW/RoofMoss_After.jpg'></Image>
      
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>Gutter Cleaning</Accordion.Header>
          <Accordion.Body>
            <p>
              I remove the debris from your gutter and rinse them out to ensure there are no blockages, and to ensure that the downspouts drain properly.
            </p>
            <p>Services start at $150</p>
            <Image className='w-50' src='../img/2024-04-PW/GutterCleaning_Before.jpg'></Image>
            <Image className='w-50' src='../img/2024-04-PW/GutterCleaning_After.jpg'></Image> 
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>Window Cleaning</Accordion.Header>
          <Accordion.Body>
            <p>
              I provide basic window cleaning to select clients as an add-on to the purchase of another service. If you would like a window cleaning only service please contact me and I will provide you with a property-specific quote.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>Commercial</Accordion.Header>
          <Accordion.Body>
            <p>
              I have experience working with large multi-building, multi-unit complexes as well as smaller town home properties. I enjoy learning about the community's needs and working together to help meet them. Please reach out to schedule a walkthrough.
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      </Card>
    </Row>
  );
}

export default Services;