import { Container, Image, Nav, Navbar, Offcanvas}  from 'react-bootstrap';


function NavBar() {
  return (
    <>
      <Navbar expand='md' className="bg-primary ">
        <Container fluid> 
          <Navbar.Brand href="#home">
              <Image src="logo.png" width="50px" className="pe-2 mt-1 mb-2" alt="logo of a red window behind two symmetrical green leafs." /> 
              <span className="text-body">AWG</span> Pressure Washing
            </Navbar.Brand>
          
          <Navbar.Toggle className='bg-secondary'/>
          
          <Navbar.Offcanvas placement="start">
            
            <Offcanvas.Header className='bg-secondary' closeButton>
              <Offcanvas.Title>
                <Image src="logo.png" width="50px" className="mb-1" alt="logo"/>
                  <span className="text-body-secondary">AWG</span> Pressure Washing
              </Offcanvas.Title>
            </Offcanvas.Header>

            <Offcanvas.Body className='bg-primary'>

              <Nav className="bg-primary justify-content-around flex-grow-1 pe-3">

                <Nav.Link href="#services">
                  <Image src="logo.png" width="40px" className="pe-2" alt="logo" />
                    Services
                </Nav.Link>

                <Nav.Link href="#gallery">
                  <Image src="logo.png" width="40px" className="pe-2" alt="logo" />
                    Gallery
                </Nav.Link>

                <Nav.Link href="#payments">
                  <Image src="logo.png" width="40px" className="pe-2" alt="logo" />
                    Payments
                </Nav.Link>

                <Nav.Link href="#contact">
                  <Image src="logo.png" width="40px" className="pe-2" alt="logo" />
                    Contact
                </Nav.Link>

              </Nav>
            </Offcanvas.Body> 
          </Navbar.Offcanvas>  
        </Container>
      </Navbar>
    </>
  );
}

export default NavBar;