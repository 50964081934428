import {Row,Carousel, Image} from 'react-bootstrap';

function GalleryCarousel() {
  return (
    <Row id="gallery" className='text-center'>
      <p className='h1 mt-4 mb-4'>
        Gallery
      </p>

      <Carousel interval={2500} className="text-center" fade>
        <Carousel.Item>
          <Image  className="w-50" src='../img/PressureWashingImages/WholeCurbBefore.jpg '/>
          <Carousel.Caption>
            <h3>Before</h3>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <Image  className="w-50" src='../img/PressureWashingImages/WholeCurbAfter.jpg' />
          <Carousel.Caption>
            <h3>After</h3>
          </Carousel.Caption>
        </Carousel.Item>


        <Carousel.Item>
          <Image  className="w-50" src='../img/PressureWashingImages/MediumCurbBefore.jpg ' />
          <Carousel.Caption>
            <h3>Before</h3>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <Image  className="w-50" src='../img/PressureWashingImages/MediumCurbAfter.jpg ' />
          <Carousel.Caption>
            <h3>After</h3>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <Image  className="w-50" src='../img/PressureWashingImages/FenceBefore.jpg ' />
          <Carousel.Caption>
            <h3>Before</h3>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <Image  className="w-50" src='../img/PressureWashingImages/FenceAfter.jpg ' />
          <Carousel.Caption>
            <h3>After</h3>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <Image  className="w-50" src='../img/PressureWashingImages/ShortCurbContrast.jpg ' />
          <Carousel.Caption>
            <h3>Contrast</h3>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <Image  className="w-50" src='../img/PressureWashingImages/ShortCurbAfter.jpg ' />
          <Carousel.Caption>
            <h3>After</h3>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <Image  className="w-50" src='../img/PressureWashingImages/SignFrontBefore.jpg ' />
          <Carousel.Caption>
            <h3>Before</h3>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <Image  className="w-50" src='../img/PressureWashingImages/SignFrontAfter.jpg ' />
          <Carousel.Caption>
            <h3>After</h3>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <Image  className="w-50" src='../img/PressureWashingImages/LargeWallBefore.jpg ' />
          <Carousel.Caption>
            <h3>Before</h3>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <Image  className="w-50" src='../img/PressureWashingImages/LargeWallAfter.jpg ' />
          <Carousel.Caption>
            <h3>After</h3>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <Image  className="w-50" src='../img/PressureWashingImages/SmallWallBefore.jpg ' />
          <Carousel.Caption>
            <h3>Before</h3>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <Image  className="w-50" src='../img/PressureWashingImages/SmallWallAfter2.jpg ' />
          <Carousel.Caption>
            <h3>After</h3>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <Image  className="w-50" src='../img/PressureWashingImages/MediumCurbContrast.jpg ' />
          <Carousel.Caption>
            <h3>Contrast</h3>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </Row>
  );
}

export default GalleryCarousel;