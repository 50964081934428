import {Row, Card, Image} from 'react-bootstrap'

import BuyNowButton from './buy-now-button'

function Payments() {
  return (
    <Row className="justify-content-center bg-white">

    <p className='bg-secndary text-center mt-4 h1 '>
      Payments
    </p>

    <p id='payments' className='mt-4 h5 text-center'>
      "I am diligent about building deep value into each project,
    </p>  

    <p className='mt-4 h5 text-center'>
    Ensuring that each job is completed at a high-value standard."
    </p> 

    <p className='mt-5 text-center'>
      Please select your payment method.
    </p>

    <Card className="border-0 col-3 bg-white">
      <p className='text-center h6'>Venmo</p>
      <a href='https://venmo.com/u/Michael_Treat'>
        <Card className= "border-0 bg-white">
          <Image src='./Venmo.png' ></Image>
        </Card> 
      </a>
      </Card>

      <Card className= "border-0 col-3 bg-white">
      <p className='text-center h6 me-5'>Stripe</p>
        <BuyNowButton></BuyNowButton>
      </Card>
  </Row>
  )
}

export default Payments;