import {Row,Image,Card} from 'react-bootstrap'

function Foot(){
  return (
    <Row id='contact' className='bg-primary col-12'>
      <Card className='ms-5 col-2 bg-primary border-0 justify-content-center'>
        <Image src="logo.png" width="25%" className="pe-2 text-align-center" alt="logo" />
      </Card>

      <Card id="contact" className='bg-primary border-0 col-4'>

        <p className='ms-3 text-start h4'>Email:</p>
        <a href="mailto:MichaelTreat@applewindowsandgutter.com?&subject=AWG-Pressure Washing Info Request" className='ms-5 pb-3 fw-light '> MichaelTreat@AppleWindowsAndGutter.com</a>
        
      </Card>
      <Card className='col-4 bg-primary border-0'>
        
        <p className='ms-3 h4 text-start'>Phone:</p>
        <a href="tel:206-512-6275" className='ms-5 fw-light '>(206)512-6275</a></Card>
    </Row>
  )
}

export default Foot;